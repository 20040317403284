import Vue from "vue";
import AgoraRTC, {
  ClientRole,
  IAgoraRTCClient,
  IAgoraRTCRemoteUser,
  ILocalAudioTrack,
  ILocalTrack,
  ILocalVideoTrack,
  IMicrophoneAudioTrack,
  IRemoteAudioTrack,
  IRemoteVideoTrack,
  SDK_CODEC,
  SDK_MODE,
  UID
} from "agora-rtc-sdk-ng";
import { PluginFunction } from "vue";
import { isMobileSafari } from "mobile-device-detect";
import IRTCStats from "./rtc-stats-interface";

export class AgoraVue {
  public static install: PluginFunction<AgoraRTCClientOptions> = (__instance, options) => {
    __instance.prototype.$agoraClient = new AgoraRTCClient(options);
    __instance.prototype.$agoraClientScreen = new AgoraRTCClient(options);
  };
}
export default class AgoraRTCClient implements EventTarget {
  screenTrack: ILocalVideoTrack | [ILocalVideoTrack, ILocalAudioTrack];
  enableStats() {
    this.disableStats();
    this._statsTimer = window.setInterval(() => {
      if (!this._client) return;
      const rtcStats = this._client.getRTCStats();
      const localVideoStats = this._client.getLocalVideoStats();
      const localAudioStats = this._client.getLocalAudioStats();
      const statistics: IRTCStats = {
        uid: this._uid,
        accessDelay: rtcStats.RTT,
        videoSendFrameRate: localVideoStats.sendFrameRate,
        videoSendResolutionWidth: localVideoStats.sendResolutionWidth,
        videoSendResolutionHeight: localVideoStats.sendResolutionHeight,
        videoSendBitRate: localVideoStats.sendBitrate,
        audioSendBitRate: localAudioStats.sendBitrate,
        audioCodecType: localAudioStats.codecType,
        audioSendVolumeLevel: localAudioStats.sendVolumeLevel
      };
      this.dispatchEvent(
        new CustomEvent<IRTCStats>("stats-available", {
          detail: statistics
        })
      );
      // this.$emit("stats-available", statistics);
    }, 1000);
  }

  disableStats() {
    if (this._statsTimer) {
      window.clearInterval(this._statsTimer);
      this._statsTimer = null;
    }
  }

  private _client: IAgoraRTCClient;
  private _appId: string;
  private _uid: UID;
  private _channel: string;
  private _statsTimer: number;

  private _devices: MediaDeviceInfo[];
  publishingTracks: boolean;

  constructor(options: AgoraRTCClientOptions) {
    this._appId = options.appId;
    AgoraRTC.setLogLevel(0);
    this._client = AgoraRTC.createClient({
      codec: options.codec || this._getDefaultCodec(),
      mode: options.mode || this._getDefaultMode(),
      role: options.role || this._getDefaultRole()
    });
  }

  private eventDelegate = document.createDocumentFragment();

  addEventListener(type: string, listener: EventListenerOrEventListenerObject, options?: boolean | AddEventListenerOptions): void {
    this.eventDelegate.addEventListener.apply(this.eventDelegate, [type, listener, options]);
  }
  dispatchEvent(event: Event): boolean {
    return this.eventDelegate.dispatchEvent.apply(this.eventDelegate, [event]);
  }
  removeEventListener(type: string, callback: EventListenerOrEventListenerObject, options?: boolean | EventListenerOptions): void {
    this.eventDelegate.removeEventListener.apply(this.eventDelegate, [type, callback, options]);
  }

  async subscribe(
    user: IAgoraRTCRemoteUser,
    video: boolean,
    audio: boolean,
    playVideoTo?: string,
    fit: "cover" | "contain" | "fill" = "cover"
  ): Promise<{ videoTrack?: IRemoteVideoTrack; audioTrack?: IRemoteAudioTrack }> {
    const self = this;
    let result: { videoTrack?: IRemoteVideoTrack; audioTrack?: IRemoteAudioTrack } = {};
    if (user.hasVideo && video) {
      console.debug("waiting Agora client to subscribe to user/camera stream");
      const track = await this._client.subscribe(user, "video");
      console.debug("Agora client subscribed to user/camera stream");
      if (playVideoTo) {
        console.debug(`Playing video stream to ${playVideoTo}`);
        track.play(playVideoTo, {
          fit: fit
        });
        track.on("first-frame-decoded", () => {
          console.debug("Video first frame decoded, setting video player features");

          const { video, width, height } = this.setupVideoAfterFirstFrameDecoded(track, playVideoTo);

          self.dispatchEvent(
            new CustomEvent<VideoStartedEventArgs>("video-started", { detail: { videoElement: video, userId: user.uid, videoHeight: height, videoWidth: width } })
          );
        });
      }
      result = { ...result, videoTrack: track };
    }
    if (user.hasAudio && audio) {
      const track = await this._client.subscribe(user, "audio");
      track.play();
      result = { ...result, audioTrack: track };
    }
    return result;
  }

  setupVideoAfterFirstFrameDecoded(track: IRemoteVideoTrack, playVideoTo: string): { video: HTMLVideoElement; width: number; height: number } {
    const { width, height } = track.getCurrentFrameData();
    this.transparentizeVideoContainer(playVideoTo);
    const video = document.querySelector(`#${playVideoTo} video`) as HTMLVideoElement;
    const ua = navigator.userAgent.toLowerCase();
    if (/safari/.test(ua) && !/chrome/.test(ua)) {
      window.setTimeout(() => {
        video.play();
      }, 0);
    }
    video.style.objectFit = width < height ? "contain" : "cover";
    video.parentElement.classList.toggle("blurBackground", width < height);
    if (width < height) {
      video.style.width = "auto";
      video.style.left = "0";
      video.style.right = "0";
      video.style.margin = "auto";
    }
    return { video, width, height };
  }

  transparentizeVideoContainer(playVideoTo) {
    const videoContainer = document.querySelector(`#${playVideoTo} > div`) as HTMLElement;
    videoContainer.style.backgroundColor = "transparent";
  }

  async unsubscribe(user: IAgoraRTCRemoteUser, video: boolean, audio: boolean) {
    if (video && user?.videoTrack) {
      user.videoTrack.stop();
      self.dispatchEvent(
        new CustomEvent<UID>("video-stopped", { detail: user.uid })
      );
    }
    if (audio && user.hasAudio) {
      user.audioTrack.stop();
    }
    if (this._client.remoteUsers.findIndex(u => u.uid === user.uid) < 0) return;
    video && (await this._client.unsubscribe(user, "video"));
    audio && (await this._client.unsubscribe(user, "audio"));
  }

  async createAudioTrack(selectedDevice?: MediaDeviceInfo): Promise<IMicrophoneAudioTrack> {
    if (!selectedDevice) {
      const devices = await this.getDevices("audioinput");
      if (devices.length == 0) return null;
      selectedDevice = devices[0];
    }
    return await AgoraRTC.createMicrophoneAudioTrack({
      microphoneId: selectedDevice.deviceId
    });
  }

  async publishTracks(tracks: ILocalTrack | ILocalTrack[]) {
    if ("some" in tracks && tracks.some(t => t as ILocalVideoTrack)) {
      this._client.localTracks.filter(t => t as ILocalVideoTrack).forEach(async t => await this._client.unpublish(t));
    }
    await this._client.publish(tracks);
    this.publishingTracks = true;
  }

  async unpublishTracks(tracks?: ILocalTrack | ILocalTrack[]) {
    await this._client.unpublish(tracks);
  }

  async publishScreen(playTo: string) {
    this.screenTrack = await AgoraRTC.createScreenVideoTrack(
      {
        screenSourceType: "screen",
        encoderConfig: "720p_2",
        optimizationMode: "motion"
      },
      "auto"
    );
    const vm = this;
    const trackResult: ILocalVideoTrack = this.screenTrack[0] || this.screenTrack;
    trackResult.on("track-ended", function() {
      vm.dispatchEvent(new CustomEvent("screen-share-ended"));
    });
    await this._client.publish(this.screenTrack);
    trackResult.play(playTo);
    const video = document.querySelector(`#${playTo} video`);
    video.parentElement.classList.toggle("blurBackground");

    this.transparentizeVideoContainer(playTo);
  }

  async unpublishScreen() {
    await this._client.unpublish(this.screenTrack);
    const trackResult = this.screenTrack[0] || this.screenTrack;
    trackResult.stop();
    trackResult.close();
  }

  async createCustomVideoTrack(camera: MediaStreamTrack): Promise<ILocalVideoTrack> {
    console.log("CREATING CUSTOM CAMERA TRACK!!!");
    return AgoraRTC.createCustomVideoTrack({
      mediaStreamTrack: camera,
      bitrateMax: 5000,
      bitrateMin: 3500,
      optimizationMode: "detail"
    });
  }

  async createVideoTrack(selectedDevice?: MediaDeviceInfo): Promise<ILocalVideoTrack> {
    const device = selectedDevice || (await this.getDevices("videoinput"))[0];
    if (device == null) return null;
    const result = await AgoraRTC.createCameraVideoTrack({
      cameraId: device.deviceId,
      encoderConfig: "720p_2",
      optimizationMode: "detail"
    });
    return result;
  }

  async getDevices(kind?: MediaDeviceKind): Promise<MediaDeviceInfo[]> {
    let devices = this._devices || (this._devices = await AgoraRTC.getDevices());

    if (kind) {
      devices = devices.filter(d => d.kind === kind);
    }
    return devices;
  }

  async join(channel: string, uid?: UID, token?): Promise<UID> {
    try {
      if (["CONNECTED", "CONNECTING", "RECONNECTING"].includes(this._client.connectionState)) return;
      if (this._channel && this._channel !== channel) {
        await this._client.leave();
      }
      uid = await this._client.join(this._appId, channel, token, uid);

      this._channel = channel;
      this._uid = uid;
      return uid;
    } catch (e) {
      console.error(`Error joining channel ${channel}`, e);
    }
  }

  async leave() {
    if (["DISCONNECTED", "DISCONNECTING"].includes(this._client.connectionState)) return;
    this._client.localTracks.forEach(t => {
      t.getMediaStreamTrack().stop();
      t.stop();
    });
    await this.unpublish();
    await this._client.leave();
    this._channel = null;
  }

  async unpublish(tracks?: ILocalTrack | ILocalTrack[]) {
    await this._client.unpublish(tracks);
  }

  removeAllListeners(event: string) {
    this._client.removeAllListeners(event);
  }

  on(
    event:
      | "connection-state-change"
      | "user-joined"
      | "user-left"
      | "user-published"
      | "user-unpublished"
      | "user-info-updated"
      | "media-reconnect-start"
      | "media-reconnect-end"
      | "stream-type-changed"
      | "stream-fallback"
      | "channel-media-relay-state"
      | "channel-media-relay-event"
      | "volume-indicator"
      | "crypt-error"
      | "token-privilege-will-expire"
      | "token-privilege-did-expire"
      | "network-quality"
      | "live-streaming-error"
      | "live-streaming-warning"
      | "stream-inject-status"
      | "exception"
      | "is-using-cloud-proxy",
    sender: any,
    callback: (...args: any[]) => void
  ) {
    this._client.on(event, function(...args) {
      callback.apply(sender, [...args]);
    });
  }

  private _getDefaultCodec(): SDK_CODEC {
    return "vp8";
    return "h264";
  }

  private _getDefaultRole(): ClientRole {
    return "host";
  }

  private _getDefaultMode(): SDK_MODE {
    return "live";
  }
}

export class AgoraRTCClientOptions {
  codec?: SDK_CODEC;
  mode?: SDK_MODE;
  role?: ClientRole;
  appId: string;

  constructor(appId: string, codec?: SDK_CODEC, mode?: SDK_MODE, role: ClientRole = "host") {
    this.appId = appId;
    this.codec = codec;
    this.mode = mode;
    this.role = role;
  }
}

export interface VideoStartedEventArgs {
  videoElement: HTMLVideoElement;
  userId: UID;
  videoWidth: number;
  videoHeight: number;
}
