






import Vue from "vue";
export default class App extends Vue {
  get isStaging(): boolean {
    return process.env.VUE_APP_ENV == "staging";
  }
}
