import { PluginFunction } from "vue";
import SignalRProxy from "../signalr/signalr-proxy";

export default class LogSend {
  private sinks: ILogSink[] = [];

  public static install: PluginFunction<LogSendOptionsBuilder> = (__instance, options) => {
    const ls = options.build(__instance.prototype.$signalr);
    __instance.prototype.$logSend = ls;
  };
  __ori_log: any = function() {};
  __ori_warn: any = function() {};
  __ori_error: any = function() {};
  appName: string;
  private _buffer: ILogEntry[];
  private _useBuffer: boolean;
  private _bufferLength: number;
  private _context: Map<string, any> = new Map<string, any>();
  private _enabled: boolean;
  __ori_debug: { (...data: any[]): void; (message?: any, ...optionalParams: any[]): void };
  signalrProxy: SignalRProxy;

  constructor(options: LogSendOptions, signalrProxy?: SignalRProxy) {
    this.sinks = options.sinks;
    this.appName = options.appName;
    this.signalrProxy = signalrProxy;

    if (+options.bufferLength > 1) {
      this._buffer = [];
      this._useBuffer = true;
      this._bufferLength = options.bufferLength;
    }
  }

  enable() {
    // if (/localhost/.test(location.href)) return;
    if (this._enabled) return;
    this.__ori_log = window.console.log;
    this.__ori_warn = window.console.warn;
    this.__ori_error = window.console.error;
    this.__ori_debug = window.console.debug;
    const that = this;
    window.console.debug = function(message?: any, ...args: any[]) {
      const d = new Date(that.getCurrentTime());
      const messagetime =
        `00${d.getHours()}`.substr(-2) + ":" + `00${d.getMinutes()}`.substr(-2) + ":" + `00${d.getSeconds()}`.substr(-2) + ":" + `00${d.getMilliseconds()}`.substr(-3) + " ";
      that.__ori_debug(messagetime + message, ...args);
      that._log("debug", messagetime + message, args);
    };
    window.console.log = function(message?: any, ...args: any[]) {
      const d = new Date(that.getCurrentTime());
      const messagetime =
        `00${d.getHours()}`.substr(-2) + ":" + `00${d.getMinutes()}`.substr(-2) + ":" + `00${d.getSeconds()}`.substr(-2) + ":" + `00${d.getMilliseconds()}`.substr(-3) + " ";

      that.__ori_log(messagetime + message, ...args);
      that._log("log", messagetime + message, args);
    };
    window.console.warn = function(message?: any, ...args: any[]) {
      const d = new Date(that.getCurrentTime());
      const messagetime =
        `00${d.getHours()}`.substr(-2) + ":" + `00${d.getMinutes()}`.substr(-2) + ":" + `00${d.getSeconds()}`.substr(-2) + ":" + `00${d.getMilliseconds()}`.substr(-3) + " ";

      that.__ori_warn(messagetime + message, ...args);
      that._log("warn", messagetime + message, args);
    };
    window.console.error = function(message?: any, ...args: any[]) {
      const d = new Date(that.getCurrentTime());
      const messagetime =
        `00${d.getHours()}`.substr(-2) + ":" + `00${d.getMinutes()}`.substr(-2) + ":" + `00${d.getSeconds()}`.substr(-2) + ":" + `00${d.getMilliseconds()}`.substr(-3) + " ";

      that.__ori_error(messagetime + message, ...args);
      that._log("error", messagetime + message, args);
    };
    this._enabled = true;
  }

  getCurrentTime(): string | number | Date {
    return (this.signalrProxy && this.signalrProxy.currentTime && new Date(this.signalrProxy.currentTime)) || new Date();
  }

  private async _log(level: string, message?: any, ...optionalParams: any[]): Promise<void> {
    if (this._useBuffer) {
      this._buffer.push({
        level,
        context: this._context,
        message,
        optionalParams
      });
      if (this._buffer.length > this._bufferLength) {
        const vm = this;
        new Promise<void>(resolve => {
          try {
            const bufferCopy = [...vm._buffer];
            vm._buffer = [];
            bufferCopy.forEach(async element => {
              await vm._sendToSinks(element);
            });
            resolve();
          } catch (error) {
            //
          }
        });
      }
    }
    await this._sendToSinks({
      level,
      context: this._context,
      message,
      optionalParams
    });
  }

  addToContext(key: string, value: any) {
    this._context[key] = value;
  }

  private async _sendToSinks(entry: ILogEntry) {
    if (this.sinks)
      this.sinks.forEach(async sink => {
        try {
          await sink.writeLog(this.appName, entry);
        } catch (error) {
          this.__ori_warn("failed writing to sink, log line could be lost");
        }
      });
  }
}

export class LogSendOptionsBuilder {
  options = new LogSendOptions();
  public build(signalrProxy?: SignalRProxy): LogSend {
    return new LogSend(this.options, signalrProxy);
  }

  withSinks(...sinks: ILogSink[]): LogSendOptionsBuilder {
    this.options.sinks = this.options.sinks.concat(sinks);
    return this;
  }

  withAppName(appName: string): LogSendOptionsBuilder {
    this.options.appName = appName;
    return this;
  }

  withBuffer(bufferLength: number): LogSendOptionsBuilder {
    this.options.bufferLength = bufferLength;
    return this;
  }
}

export class LogSendOptions {
  sinks?: ILogSink[] = [];
  appName: string;
  bufferLength: number;

  constructor() {
    this.appName = location.hostname;
    this.bufferLength = 0;
  }
}

export interface ILogSink {
  writeLog(appName: string, entry: ILogEntry);
}

export interface ILogEntry {
  level: string;
  context: Map<string, any>;
  message: string;
  optionalParams: any[];
}
