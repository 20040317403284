
















import { Component, Prop, Vue } from "vue-property-decorator";
import CustomButton from "../components/CustomButton.vue";

@Component({
  components: { CustomButton }
})
export default class SellerButtons extends Vue {
  @Prop() camera1available: boolean;
  @Prop() camera2available: boolean;
  @Prop() visible: boolean;
  @Prop() camera1active: boolean;
  @Prop() camera2active: boolean;
  @Prop() activeCamera: number;
  @Prop() sharingScreen: boolean;

  changeCamera(cameraUid) {
    this.$emit("change-camera", cameraUid);
  }

  shareScreen() {
    this.$emit("share-screen");
  }

  public get isRealMode(): boolean {
    return process.env.VUE_APP_UIMODE == "real";
  }
}
