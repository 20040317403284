














import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({
  name: "CustomSelect"
})
export default class CustomSelect extends Vue {
  @Prop({ required: false, default: null }) default: any;
  @Prop({ type: Array, required: true }) options: any[];
  @Prop({ type: Number, required: false, default: 0 }) tabindex: number;
  defaultLabel: string = "";
  @Prop({ type: String }) placeHolder: string;
  @Prop({ type: Boolean, required: false }) small: boolean;
  private selected: any = null;
  private open: boolean = false;

  onSelected(option: any) {
    this.open = false;
    if (option != this.selected) {
      this.selected = option;
      this.defaultLabel = this.getDefaultLabel();
      this.$emit("input", this.selected);
    }
  }

  getDefaultLabel(): string {
    return this.selected && this.selected.name ? this.selected.name : this.selected;
  }

  public label(option): string {
    return option && option.name ? option.name : option;
  }

  @Watch("options")
  optionsWatcher() {
    this.selected = this.selected || (this.default ? this.default : this.options.length > 0 ? this.options[0] : null);
    this.defaultLabel = this.getDefaultLabel();
  }

  mounted() {}
}
