//store/modules/auth.js

import axios from "axios";

const state: State = {
  user: undefined,
};

const getters = {
  isAuthenticated: (state: State) => !!state.user,
  StateUser: (state: State) => state.user,
};

const actions = {
  async LogIn(commit: any, user: User) {
    await axios.post("login", user);
    await commit("setUser", user.username);
  },

  async Logout(commit: any) {
    await commit("logout", null);
  },
};

const mutations = {
  setUser(state: State, username: string) {
    state.user = username;
  },

  logout(state: State) {
    state.user = undefined;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

interface User {
  username: string;
}

interface State {
  user?: string;
}
