





















import Component from "vue-class-component";
import BaseVue from "@/utilities/base-vue";
import { HubConnectionState } from "@microsoft/signalr";
import md5 from "md5";
import { Prop } from "vue-property-decorator";
@Component({
  updated() {
    document.querySelectorAll(".log-content").forEach(e => {
      e.scrollTo(0, e.scrollHeight);
    });
  }
})
export default class Logs extends BaseVue {
  panels = [
    { name: "Seller", id: 1111, logs: [] },
    { name: "Buyer", id: 3333, logs: [] },
    { name: "Camera1", id: 10001, logs: [] },
    { name: "Camera2", id: 10002, logs: [] }
  ];

  @Prop({ type: String }) booth: string;

  /**
   *
   */
  constructor() {
    super();
  }

  async mounted() {
    await new Promise<void>(resolve => {
      const i = setInterval(() => {
        if (this.$signalr.connection.state == HubConnectionState.Connected) {
          this.$logSend.__ori_log("ok go");
          clearInterval(i);
          resolve();
        }
      }, 250);
    });
    this.$signalr.connection.send("JoinGroup", "Logs__" + this.$logSend.appName);
    const vm = this;
    this.$signalr.connection.on("LogEvent", data => {
      if (vm.booth && data.context.booth !== vm.booth) return;
      const uid = data.context["uid"];
      if (!uid) return;
      const dest = vm.panels.find(p => p.id === +uid)?.logs;
      if (!dest) return;
      const timestamp = document.timeline.currentTime;
      const key = md5(data.level + uid + timestamp + data.message);
      if (dest.some(d => d.timestamp === key)) return;
      dest.push({
        timestamp,
        ...data
      });
    });
  }

  async askReload(id: number) {
    try {
      await this.$signalr.sendRefreshRequest(id);
    } catch (error) {
      console.error("error occurred sending refresh request", error);
      alert("error occurred sending refresh request: " + error);
    }
  }

  clearLogs(id: number) {
    document.querySelector(`#logs-${id}`).innerHTML = "";
  }
}
