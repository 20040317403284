







import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class VideoZoom extends Vue {
  @Prop() video: HTMLVideoElement;
  @Prop({ type: Object }) coordinates: any;
  
  private showCanvas: boolean = false;

  @Watch("coordinates")
  onPropertyChanged(value: any) {
    if (value) {
      this.showCanvas = true;
      setTimeout(this.capture, 100);
    }
  }

  capture() {
    const videoW = this.video.videoWidth;
    const videoH = this.video.videoHeight;
    const canvasH = document.body.clientHeight;
    const canvasW = document.body.clientWidth;
    const canvas: any = document.getElementById('zoom');
    canvas.height = canvasH;
    canvas.width = canvasW;
    const scale = canvasW/videoW;
    const imageY= -this.coordinates.y*((videoH*scale-canvasH)/canvasH);
    const ctx = canvas.getContext('2d');
    ctx.drawImage(this.video, 0, imageY, videoW*scale, videoH*scale);
  }

  delCanvas(e){
    this.showCanvas = false;
    e.preventDefault();
  }
}
