






















import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
@Component({})
export default class ByePage extends Vue {
  @Prop() callSlug: string;

  returnToCall() {
    if (this.callSlug) {
      this.$router.replace(`/${this.callSlug}`);
    }
  }

  visitSite() {
    window.location.href = "https://www.goldengoose.com/";
  }
}
