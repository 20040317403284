
















import BaseVue from "../utilities/base-vue";
import { Component } from "vue-property-decorator";
import RtcStatsPanel from "../components/rtc-stats-panel.vue";
import { IAgoraRTCRemoteUser } from "agora-rtc-sdk-ng";
@Component({
  name: "Camera",
  components: {
    RtcStatsPanel
  },
  props: {
    booth: {
      type: String,
      default: "milano1"
    },
    cameraId: {
      type: String,
      default: "1"
    }
  }
})
export default class Camera extends BaseVue {
  _publishing = false;
  private _uid: number;
  media: MediaStream;
  protected me: Camera;
  unpublishAndLeaveTimeout: number;
  unpublishAndLeavePromise: Promise<void> = Promise.resolve();

  /**
   *
   */
  constructor() {
    super();
    console.log("creating camera component " + this.$props.cameraId);
    this._uid = +this.$props.cameraId;
    this.$logSend.addToContext("booth", this.$props.booth);
    this.$logSend.addToContext("uid", this._uid);
    this.$logSend.enable();

    this.$signalr.onChangeCameraRequest(this, this.onChangeCameraRequest);

    this.$signalr.onJoinedBoothControl(this, this.onJoinedBoothControl);

    this.$signalr.onRefreshRequest((requestingUser: string) => {
      console.log(`${requestingUser} requested a page refresh`);
      location.reload();
    });
  }

  async onJoinedBoothControl(_, booth, uid) {
    console.debug(`User ${uid} joined booth control channel`);
    if (uid === 1111) {
      if (this.unpublishAndLeaveTimeout) {
        console.debug(`User is seller and previous unpublish and leave timeout is active`);
        window.clearTimeout(this.unpublishAndLeaveTimeout);
        this.unpublishAndLeaveTimeout = null;
      }
    }
  }

  async onRemoteUserLeft(sender: Camera, user: IAgoraRTCRemoteUser) {
    console.debug(`user ${user.uid} left the channel`);
    if (user.uid === 1111) {
      if (this.unpublishAndLeaveTimeout) {
        console.debug("a previous unpublish timeout is present, resetting it");
        window.clearTimeout(this.unpublishAndLeaveTimeout);
        this.unpublishAndLeaveTimeout = null;
      }
      const timeoutMin = 10;
      if (this._publishing) {
        console.debug(`Camera is still publishing, setting unpublish and leave timeout of ${timeoutMin} minutes`);
        this.unpublishAndLeaveTimeout = window.setTimeout(async () => {
          console.debug(`seller did not return in ${timeoutMin} minutes, leaving channel`);
          this.unpublishAndLeavePromise = this.unpublishAndLeave();
          await this.unpublishAndLeavePromise;
        }, timeoutMin * 60 * 1000);
      }
    }
  }

  async onChangeCameraRequest(cameraId: number) {
    console.log(`onChangeCameraRequest ${cameraId}`);
    if (this.unpublishAndLeaveTimeout) {
      window.clearTimeout(this.unpublishAndLeaveTimeout);
      this.unpublishAndLeaveTimeout = null;
    }
    if (cameraId === this._uid) {
      await this.unpublishAndLeavePromise;
      if (this._publishing) {
        console.debug("Camera is still publishing");
        await this.$signalr.sendCameraStillPublishing(this._uid, this.$props.booth);
      }
      await this.joinBoothChannel(this._uid, this.$props.booth);
      await this.publish();
    } else if (this._publishing) {
      await this.unpublishAndLeave();
    }
  }

  async unpublishAndLeave() {
    console.debug("Unpublishing stream and leaving channel");
    await this.unpublish();
    await this.leaveBoothChannel();
  }

  async mounted() {
    console.log("mounting");
    try {
      await this.joinBoothControl(this._uid, this.$props.booth, "camera");

      var stream = await navigator.mediaDevices.getUserMedia({
        audio: false,
        video: {
          facingMode: "environment",
          width: { min: 1278, ideal: 1278 },
          height: { min: 720, ideal: 720 },
          frameRate: { min: 15, ideal: 30 },
          aspectRatio: { ideal: 1.7777778 }
        }
      });

      const track = stream.getVideoTracks()[0];

      // try {
      //   console.log("trying to switch on the torch");
      //   const capture = new ImageCapture(track);
      //   const capabilities: PhotoCapabilities = await capture.getPhotoCapabilities();
      //   if (capabilities.fillLightMode) {
      //     console.log("switching on torch");
      //     track.applyConstraints({ advanced: [{ torch: true }] });
      //   } else {
      //     console.warn("torch not available on this device");
      //   }
      // } catch (error) {
      //   console.error("could not start torch on this device", error);
      // }

      await this.getCameraTrack(track);

      await this.playVideoTrackTo("local_stream");
    } catch (e) {
      console.error(e);
      alert("There was an error initializing the page, please refresh to retry");
    }
  }

  sendFullscreen() {
    (this.$refs["videoView"] as HTMLElement).requestFullscreen();
  }

  async publish(event?: MouseEvent) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    await this.publishTracks(this.localVideoTrack);
    this._publishing = true;
  }

  async unpublish(event?: MouseEvent) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    await this.unpublishTracks(this.localVideoTrack);
    this._publishing = false;
  }

  public async beforeDestructor() {
    console.log("switching off torch");
    this.localVideoTrack.getMediaStreamTrack().applyConstraints({ advanced: [{ torch: false }] });
  }
}
