





























































































































































import BaseVue from "../utilities/base-vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import ControlPanel from "../components/ControlPanel.vue";
import SellerButtons from "../components/SellerButtons.vue";
import CustomOptions from "../components/CustomOptions.vue";
import CustomButton from "../components/CustomButton.vue";
import AudioInput from "../components/AudioInput.vue";
import AudioOutput from "../components/AudioOutput.vue";
import RtcStatsPanel from "../components/rtc-stats-panel.vue";
import Modal from "../components/Modal.vue";
import CustomSelect from "../components/CustomSelect.vue";
import VideoZoom from "../components/VideoZoom.vue";
import { IAgoraRTCRemoteUser, IRemoteAudioTrack, IRemoteVideoTrack, UID } from "agora-rtc-sdk-ng";
import AgoraRTCClient, { VideoStartedEventArgs } from "@/agora-rtc";
import { CallMode } from "../utilities/call-mode";

@Component({
  name: "Seller",
  components: {
    SellerButtons,
    CustomOptions,
    ControlPanel,
    CustomButton,
    RtcStatsPanel,
    Modal,
    CustomSelect,
    AudioInput,
    AudioOutput,
    VideoZoom
  },
  props: {
    // booth: {
    //   type: String,
    //   default: "milano1"
    // }
  },
  data() {
    return {};
  }
})
export default class Seller extends BaseVue {
  public camera1isInControlRoom = false;
  public camera2isInControlRoom = false;
  public camera1isInRtcChannel = false;
  public camera2isInRtcChannel = false;
  public camera1active = false;
  public camera2active = false;
  public sharingScreen = false;
  public activeCamera = 0;
  public activeMenu = false;
  public activeSettings = false;
  public modalOpen = false;
  public assistantActive = false;
  public settingsPanelMode = "audio";
  public tabFocus = false;
  public idleStatus = null;
  statsEnabled = false;
  public $route: any;
  protected me: Seller;
  private uid = 1111;
  public coords = null;
  public outfitVideo: HTMLVideoElement = null;
  mode = CallMode.OffCall;
  @Prop({ default: "milano1" }) booth: string;
  $agoraClientScreen: AgoraRTCClient;
  interfaceIsVisible = true;
  currentCallSlug: string;
  callUrl: string = "";
  customerVideo: any = null;

  constructor() {
    super();
    const vm = this;
    if (this.isRealMode) {
      const existingCallSlug = localStorage.getItem("current-call-slug");
      if (existingCallSlug)
        this.$gooseapi
          .getCallBySlug(existingCallSlug)
          .then(call => {
            this.setCurrentCall(!call.isClosed && new Date(call.endsAtUtc) > new Date() ? existingCallSlug : null);
          })
          .catch(() => this.setCurrentCall(null));
    }
    this.$logSend.addToContext("uid", this.uid);
    this.$logSend.addToContext("booth", this.booth);
    this.$logSend.enable();
    this.$signalr.onJoinedBoothControl(this, (...args) => {
      this.onJoinedBoothControl(this, ...args);
    });

    this.$signalr.onCameraStillPublishing(this, this.onCameraStillPublishing);

    this.$signalr.onLeftBoothControl(this, (...args) => {
      this.onLeftBoothControl(this, ...args);
    });

    this.$agoraClientScreen.addEventListener("screen-share-ended", function() {
      vm.onChangeCamera(0);
    });

    this.$agoraClient.addEventListener("video-started", this.onVideoStarted);

    document.addEventListener("visibilitychange", () => {
      console.log("Visibility changed to " + document.visibilityState);
      document.visibilityState == "hidden" ? vm.onTabHidden() : vm.onTabVisible();
    });
  }

  onCameraStillPublishing(caller: Seller, cameraId: number) {
    this.resolvePublishingCamera(cameraId);
  }

  async onTabVisible() {
    console.debug("Tab returned visible");
    const latestCamera = +sessionStorage.getItem("current-active-camera");
    if (latestCamera !== this.activeCamera) {
      // const { success, videoTrack } =
      await this.onChangeCamera(latestCamera, true, true);
      sessionStorage.removeItem("current-active-camera");
    }
  }
  async onTabHidden() {
    console.debug("Tab gone hidden");
    if ([10001, 10002].includes(this.activeCamera)) {
      sessionStorage.setItem("current-active-camera", this.activeCamera.toString());
      this.activeCamera = -1;
    }
  }

  async mounted() {
    try {
      const { occupied, message } = await this.$gooseapi.occupyBooth(this.booth, "seller");
      if (!occupied) {
        alert(message);
        this.$router.push("/");
        return;
      }
      await this.joinBoothControl(this.uid, this.booth, "seller");
      // await this.joinBoothChannel(this.uid, this.booth);

      // Join del client RTC riservato allo schermo

      if (this.isRealMode) {
        await this.getCameraTrack(null);
        await this.playVideoTrackTo("local_stream");
      }
    } catch (e) {
      console.error(e);
      alert("There was an error initializing the page, please refresh to retry");
    }
  }

  public get isInCall(): boolean {
    return this.mode == CallMode.InCall;
  }

  toggleMenu(value) {
    this[value] = this[value] ? false : true;
  }

  startEvent(e) {
    if (e.target.id === "assistantButton") {
      this.assistantActive = true;
      console.log("start");
    }
  }

  stopEvent() {
    if (this.assistantActive) {
      console.log("stop");
      this.assistantActive = false;
    }
  }

  audioInputSelected(device: MediaDeviceInfo = null) {
    this.setAudioInput(device);
  }

  audioOutputSelected(device: MediaDeviceInfo = null) {
    this.setAudioOutput(device);
  }

  startHidleTimer() {
    this.idleStatus = setTimeout(() => (this.activeCamera = 0), 5 * 60 * 1000);
  }

  stopHidleTimer() {
    if (this.idleStatus) {
      clearTimeout(this.idleStatus);
      this.idleStatus = null;
    }
  }

  onVideoStarted(e: CustomEvent<VideoStartedEventArgs>) {
    const data = e.detail;
    if (data.userId === 10002 && data.videoElement.style.objectFit === "contain") {
      this.outfitVideo = data.videoElement;
      data.videoElement.addEventListener("touchstart", this.getVideoCoords);
      data.videoElement.addEventListener("mousedown", this.getVideoCoords);
    }
  }

  getVideoCoords(e: MouseEvent | TouchEvent) {
    console.log(e);
    const normalizedEvent = e instanceof TouchEvent ? e.targetTouches[0] : e;
    const targetY = normalizedEvent.clientY;
    const targetX = normalizedEvent.clientX;
    this.coords = {
      x: targetX,
      y: targetY
    };
    e.preventDefault();
  }

  created() {
    this.detectFocusOut();
  }

  @Watch("tabFocus")
  onPropertyChanged(value: boolean, oldValue: boolean) {
    if (!value && oldValue) {
      if (!this.sharingScreen && this.activeCamera) this.startHidleTimer();
    } else {
      if (this.idleStatus) this.stopHidleTimer();
    }
  }

  detectFocusOut() {
    window.addEventListener("blur", () => {
      this.tabFocus = !this.tabFocus;
    });
    window.addEventListener("focus", () => {
      this.tabFocus = true;
    });

    document.addEventListener("visibilitychange", () => {
      this.tabFocus = document.visibilityState === "visible";
    });
  }

  async joinCall() {
    try {
      await Promise.all([await this.joinBoothChannel(this.uid, this.booth), await this.joinScreenSharing()]);
      if (this.isInCall) return;
      var audioTrack = await this.getAudioTrack(null);
      if (!this.localVideoTrack) {
        this.localVideoTrack = await this.getCameraTrack(null);
      }
      await this.publishTracks([this.localVideoTrack, audioTrack]);
      this.closeModal();
      this.mode = CallMode.InCall;
    } catch (error) {
      await this.$agoraClientScreen.leave();
      await this.leaveBoothChannel();
      console.error(error);
      alert("Could not join this call, an error occurred, please check your connection and try again.");
    }
  }

  async joinScreenSharing() {
    const screenToken = await this.getAgoraToken(this.channel, 4444);
    await this.$agoraClientScreen.join(this.channel, 4444, screenToken);
  }

  async leaveCall() {
    await this.unpublishTracks();
    await this.leaveBoothChannel();
    this.mode = CallMode.OffCall;
  }

  async closeCall(force?: boolean) {
    if (!this.isInCall && !force) return;
    try {
      const closeResult = await this.$gooseapi.closeCall(this.currentCallSlug);
      if (!closeResult.success) {
        if (!confirm(`Could not propertly close this call: ${closeResult.message || "an error occurred"}.\nDo you wish to continue closing?`)) {
          return;
        }
      }
      if (this.isInCall) {
        await this.unpublishTracks();
      }
      this.setCurrentCall(null);
      this.mode = CallMode.OffCall;
    } catch (error) {
      console.error(error);
      alert(`Could not propertly close this call, check your internet connection and retry`);
    }
  }

  async logout(e: MouseEvent, silently: boolean = false, freeBooth: boolean = true) {
    if (freeBooth) {
      const { occupied, message } = await this.$gooseapi.freeBooth(this.booth);
      if (occupied && !silently) {
        alert(message);
        return;
      }
    }
    this.setCurrentCall(null);
    location.href = "/";
    if (!silently) {
      alert("You have logged out");
    }
  }

  async onRemoteUserJoined(sender, user: IAgoraRTCRemoteUser) {
    if (user.uid === 10001) {
      this.camera1isInRtcChannel = true;
    }
    if (user.uid === 10002) {
      this.camera2isInRtcChannel = true;
    }
    sender.addRemoteUser(user.uid, user);
  }

  async onRemoteUserLeft(sender, user: IAgoraRTCRemoteUser) {
    if (user.uid === 3333) {
      this.onChangeCamera(0);
      await this.leaveCall();
      this.interfaceIsVisible = false;
      return;
    }
    if (user.uid === 10001) {
      this.camera1isInRtcChannel = false;
    }
    if (user.uid === 10002) {
      this.camera2isInRtcChannel = false;
    }
    if (this.activeCamera === user.uid && !this._changingCamera) this.onChangeCamera(0);
    sender.removeRemoteUser(user.uid);
  }

  async onRemoteUserPublished(sender: Seller, user: IAgoraRTCRemoteUser) {
    if (user.uid === 3333) {
      await sender.$agoraClient.subscribe(user, true, true, "customer_stream");
      if (user.hasAudio) {
        this.remoteAudioTracks.set(user.audioTrack.getTrackId(), user.audioTrack);
      }
      if (user.hasVideo) {
        const video = await this.findElementBySelector("#customer_stream video");
        await new Promise<void>(resolve => {
          video.addEventListener(
            "progress",
            () => {
              resolve();
            },
            {
              // once: true
            }
          );
        });
        this.customerVideo = video;
        this.interfaceIsVisible = true;
      }
    }
  }

  async onRemoteUserUnpublished(sender, user: IAgoraRTCRemoteUser) {
    if ((user.uid === 10001 || user.uid === 10002) && this.activeCamera != 0) {
      this.onChangeCamera(this.activeCamera);
    }
    if (user.audioTrack) {
      this.remoteAudioTracks.delete(user.audioTrack.getTrackId());
    }
  }

  async onJoinedBoothControl(sender: Seller, connectionId: string, booth: string, uid: UID, role: string) {
    if (role === "camera") {
      if (uid === 10001) {
        this.camera1isInControlRoom = true;
      }
      if (uid === 10002) {
        this.camera2isInControlRoom = true;
      }
    }

    if (role === "buyer") {
      await this.joinCall();
    }

    if (role === "seller") {
      alert("You have been disconnected by another user");
      await this.logout(null, true, false);
    }
  }

  async onLeftBoothControl(sender: Seller, connectionId: string, booth: string, uid: UID, role: string) {
    if (role === "camera") {
      if (uid === 10001) {
        this.camera1isInControlRoom = false;
      }
      if (uid === 10002) {
        this.camera2isInControlRoom = false;
      }
    }
  }

  async onShareScreen() {
    await this.$agoraClientScreen.publishScreen("camera_stream");
    await this.onChangeCamera(4444, false);
    this.sharingScreen = true;
    this.togglePiP();
  }

  async onScreenShareEnded() {
    this.sharingScreen = false;
    await this.onChangeCamera();
    this.togglePiP();
  }

  private _changingCamera = false;
  async onChangeCamera(cameraId?: number, doSubscribe: boolean = true, forcePortraitLayout: boolean = false) {
    console.debug(`onChangeCamera event started with cameraId ${cameraId} and doSubscribe ${doSubscribe}`);
    if (this._changingCamera) return;
    if (this.activeCamera === cameraId) return;

    if (cameraId !== 0) {
      console.debug("Joining channel if not joined yet...");
      await this.joinBoothChannel(this.uid, this.booth);
      console.debug("Channel joined.");
    }
    console.debug("starting changing camera");
    this._changingCamera = true;

    const changeCameraResult = await this.tryChangeCameraWithTimeout(cameraId, doSubscribe, forcePortraitLayout);

    console.debug("camera change result: ", changeCameraResult);

    // Step 3: valuto il risultato
    if (!changeCameraResult.success) {
      // Se la camera non è cambiata ed è diversa dal default, ritorno a default
      if (cameraId) {
        await this.doChangeCamera(0);
      }
      // Avviso l'utente
      alert("There has been an issue while trying to change camera or share screen, please retry");
    }
    this._changingCamera = false;
    return changeCameraResult;
  }

  tryChangeCameraWithTimeout(
    cameraId: number,
    doSubscribe: boolean,
    forcePortraitLayout: boolean
  ): Promise<{ success: boolean; videoTrack?: IRemoteVideoTrack; audioTrack?: IRemoteAudioTrack }> {
    return new Promise<{ success: boolean; videoTrack?: IRemoteVideoTrack; audioTrack?: IRemoteAudioTrack }>(resolve => {
      // Step 1: imposto timeout; quando scatta, fa un reject della promise su cameraPublishing (vd metodo cameraIsPublishing) e risolve questa Promise tornando false (la camera non è cambiata)
      const changeCameraTimeout = setTimeout(() => {
        console.debug("change camera timedout");
        this.camerasPublishing.find(cp => cp.cameraId === cameraId)?.rejecter();
        console.debug("returning false");
        resolve({ success: false });
      }, 8000);

      console.debug("invoking doChangeCamera");

      // Step 2: invoco cambio camera
      this.doChangeCamera(cameraId, doSubscribe, forcePortraitLayout)
        // Quando la Promise viene mantenuta
        .then(changeCameraResult => {
          console.debug("doChangeCamera returned normally with result ", changeCameraResult);

          // Se la camera è cambiata, posso fare il clear del timeout
          if (changeCameraResult.success) {
            clearTimeout(changeCameraTimeout);
          }
          // Risolvo la Promise ritornando il risultato, qualunque esso sia
          resolve(changeCameraResult);
        })
        // In caso di errore
        .catch(error => {
          // Loggo l'errore (che è sempre bene) e risolvo ritornando false
          console.error("doChangeCamera raised an exception", error);
          resolve({ success: false });
        });
    });
  }

  async doChangeCamera(
    cameraId?: number,
    doSubscribe: boolean = true,
    forcePortraitLayout: boolean = false
  ): Promise<{ success: boolean; videoTrack?: IRemoteVideoTrack; audioTrack?: IRemoteAudioTrack }> {
    let result: { success: boolean; videoTrack?: IRemoteVideoTrack; audioTrack?: IRemoteAudioTrack } = { success: cameraId === 0 };
    await this.$signalr.sendChangeCamera(cameraId);
    if (this.sharingScreen) {
      await this.$agoraClientScreen.unpublishScreen();
      this.sharingScreen = false;
      this.togglePiP();
    }
    console.log(`onChangeCamera ${cameraId}`);

    if ([10001, 10002].includes(cameraId)) {
      console.log("waiting for camera to being publishing");
      let camera: IAgoraRTCRemoteUser;
      try {
        camera = await this.cameraIsPublishing(cameraId);
        console.debug(`camera ${cameraId} is now publishing`);
      } catch (error) {
        console.debug("Error waiting for camera to be publishing");
        return result;
      }
      if (doSubscribe) {
        console.log("subscribing stream");
        const playVideoTo = "camera_stream";
        const { videoTrack, audioTrack } = await this.$agoraClient.subscribe(camera, true, true, playVideoTo);
        if (forcePortraitLayout) {
          this.$agoraClient.setupVideoAfterFirstFrameDecoded(videoTrack, playVideoTo);
        }
        result = { success: true, videoTrack, audioTrack };
      }
    }


    this.activeCamera = cameraId || 0;
    this.activeMenu = false;
    console.debug("doChangeCamera: camera has changed");

    return result;
  }

  public get existsCurrentCall(): boolean {
    return !!this.currentCallSlug;
  }

  async openModal() {
    if (this.modalOpen) return;
    this.activeMenu = false;
    if (this.isInCall) {
      if (confirm(`You have a call running :\n\n${this.callUrl}\n\nIf you continue, your previous call will be closed.\n\nContinue?`)) {
        this.closeCall(true);
      }
      return;
    }
    if (this.currentCallSlug) {
      this.modalOpen = true;
      return;
    }
    const createCallResult = await this.$gooseapi.createCall(this.booth, new Date());
    if (createCallResult.success) {
      this.setCurrentCall(createCallResult.slug);
      this.modalOpen = true;
    } else {
      alert(createCallResult.message || "Can't create a new call, an error occurred. Please retry.");
      this.closeModal();
    }
  }

  setCurrentCall(slug: string) {
    this.currentCallSlug = slug;
    if (slug) {
      localStorage.setItem("current-call-slug", this.currentCallSlug);
      this.callUrl = `${location.protocol}//${location.host}`;
      this.callUrl += `/${this.currentCallSlug}`;
    } else {
      localStorage.removeItem("current-call-slug");
      this.callUrl = null;
    }
  }

  closeModal() {
    if (!this.modalOpen) return;
    this.modalOpen = false;
  }

  copyCallUrlToClipboard() {
    if (this.callUrl) navigator.clipboard.writeText(this.callUrl);
    this.closeModal();
  }

  async togglePiP() {
    const theDoc: any = document;
    if ("pictureInPictureEnabled" in document && this.customerVideo) {
      try {
        if (this.sharingScreen && this.customerVideo !== theDoc.pictureInPictureElement) {
          await this.customerVideo.requestPictureInPicture();
        } else if (!this.sharingScreen && this.customerVideo === theDoc.pictureInPictureElement) {
          await theDoc.exitPictureInPicture();
        }
      } catch (error) {
        console.log(error);
      }
    }
  }
}
