









import { Component, Vue, Prop } from "vue-property-decorator";

@Component({
  name: "CustomButton"
})
export default class CustomButton extends Vue {
  @Prop({ type: String, required: false, default: "circle" }) theme: string;
  @Prop({ type: String, required: true }) image: string;
  @Prop({ type: Boolean, default: false }) isActive: boolean;
  @Prop({ type: Boolean, default: true }) isEnabled: boolean;

  constructor() {
    super();
    // const vm = this;
  }

  doClick() {
    if (this.isEnabled) this.$emit("click");
  }

  get enabledClass(): string {
    return this.isEnabled ? "" : "button--disabled";
  }

  get buttonStyle(): string {
    return "button--" + this.theme;
  }

  get returnImage(): string {
    return `button__icon--${this.image}`;
  }
}
