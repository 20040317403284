







































import { GetCallResult } from "@/services/goose-apis";
import BaseVue from "@/utilities/base-vue";
import { IAgoraRTCRemoteUser, UID } from "agora-rtc-sdk-ng";
import Component from "vue-class-component";
import ControlPanel from "../components/ControlPanel.vue";
import SellerButtons from "../components/SellerButtons.vue";
import CustomOptions from "../components/CustomOptions.vue";
import CustomButton from "../components/CustomButton.vue";
import RtcStatsPanel from "../components/rtc-stats-panel.vue";
import Modal from "../components/Modal.vue";
import CustomSelect from "../components/CustomSelect.vue";

@Component({
  props: {
    booth: {
      type: String,
      default: "milano1"
    }
  },
  components: {
    SellerButtons,
    CustomOptions,
    ControlPanel,
    CustomButton,
    RtcStatsPanel,
    Modal,
    CustomSelect
  },
  data() {
    return {
      remoteUsers: []
    };
  }
})
export default class Buyer extends BaseVue {
  uid = 3333;
  waitingForSeller = true;
  activeCameraUser: IAgoraRTCRemoteUser;
  isCameraActive = false;
  callSlug: string;
  /**
   *
   */
  constructor() {
    super();
    this.$logSend.addToContext("uid", this.uid);
    this.$logSend.enable();
    this.$signalr.onChangeCameraRequest(this, this.onChangeCameraRequest);
    this.$signalr.onCallClosed(this, this.onCallClosed);
  }

  quitCall() {
    this.$router.push(`/bye/${this.callSlug}`);
  }

  async onCallClosed(cameraSlug?: string) {
    if (cameraSlug == this.callSlug) this.$router.replace("/bye");
  }

  async onChangeCameraRequest(cameraId: number) {
    const oldActiveCameraUser = this.activeCameraUser;
    if (cameraId != 0) {
      console.log("waiting for camera to being publishing");
      const ru = await this.cameraIsPublishing(cameraId);
      console.log("subscribing stream");
      await this.$agoraClient.subscribe(ru, true, true, "camera_stream", ru.uid === 4444 ? "contain" : "cover");
      this.activeCameraUser = ru;
      this.isCameraActive = true;
    } else {
      this.activeCameraUser = null;
      this.isCameraActive = false;
    }
    if (oldActiveCameraUser) {
      await this.$agoraClient.unsubscribe(oldActiveCameraUser, true, true);
    }
  }

  async mounted() {
    console.log("mounting");
    try {
      const existingCallInfo = JSON.parse(localStorage.getItem("callInfo") || "{}", (key, value) => {
        if (typeof value === "string" && /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/.test(value)) return new Date(value);
        return value;
      }) as GetCallResult;
      this.callSlug = existingCallInfo.slug;
      this.$logSend.addToContext("booth", existingCallInfo.booth);
      await this.joinBoothControl(this.uid, existingCallInfo.booth, "buyer");
      await this.joinBoothChannel(this.uid, existingCallInfo.booth, existingCallInfo.buyerAgoraToken);
      console.log("publish streams");
      await this.publishStreams(null, null);
      await this.playVideoTrackTo("local_stream");
    } catch (e) {
      console.error(e);
      alert("There was an error initializing the page, please refresh to retry");
    }
  }

  onRemoteUserJoined(sender, user: IAgoraRTCRemoteUser) {
    if (user.uid === 1111) {
      console.log("hei!");
      this.waitingForSeller = false;
    }
    this.addRemoteUser(user.uid, user);
  }

  onRemoteUserLeft(sender, user: IAgoraRTCRemoteUser) {
    sender.removeRemoteUser(user.uid);
  }

  onRemoteUserPublished(sender: Buyer, user: IAgoraRTCRemoteUser) {
    if (user.uid === 1111) {
      sender.$agoraClient.subscribe(user, true, true, "vendor_stream");
    }
  }

  onRemoteUserUnpublished(sender: Buyer, user: IAgoraRTCRemoteUser) {
    if (user.uid === 1111) {
      this.waitingForSeller = true;
    }
  }

  removeRemoteUser(uid: UID) {
    this.remoteUsers.delete(uid);
  }
}
