import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./services/store";
import SignalR, { ForeverPolicy } from "./services/signalr";
import LogSend, { LogSendOptionsBuilder } from "./services/logSend";
import SignalRSink from "./services/logSend/signalr-sink";
import { AgoraRTCClientOptions, AgoraVue } from "./agora-rtc";
import GooseAPIClient from "./services/goose-apis";
import vSelect from "vue-select";
import vOutsideEvents from "vue-outside-events";

Vue.config.productionTip = false;

Vue.component("v-select", vSelect);
Vue.use(vOutsideEvents);

Vue.use(SignalR, {
  url: process.env.NODE_ENV != "development" ? getSignalRServer() : getDevelopmentSignalRServer(),
  automaticRetryCallback: new ForeverPolicy()
});

Vue.use(
  LogSend,
  new LogSendOptionsBuilder()
    .withAppName("GoldenGoose Sales Booth")
    .withSinks(new SignalRSink(Vue.prototype.$signalr.connection, "SendLog"))
    .withBuffer(20)
);

Vue.use(AgoraVue, new AgoraRTCClientOptions(process.env.NODE_ENV === "production" ? "9c081bf9056f4ffca178282bee3c40b7" : "a663dae2ddd642e084981b9f49c9dc8e"));

Vue.use(GooseAPIClient, {
  baseUrl: process.env.NODE_ENV !== "development" ? getApiBaseUrl() : getDevelopmentApiBaseUrl()
});

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount("#app");

function getSignalRServer() {
  return process.env.VUE_APP_SIGNALR_URL;
}
function getDevelopmentSignalRServer() {
  return process.env.VUE_APP_SIGNALR_URL || (/localhost/.test(location.href) ? "https://localhost:5005/salesboothws" : "https://goosewssdev.eu.ngrok.io/salesboothws");
}
function getDevelopmentApiBaseUrl(): any {
  return /localhost/.test(location.href) ? "https://localhost:5005/api" : "https://goosewssdev.eu.ngrok.io/api";
}
function getApiBaseUrl(): any {
  return process.env.VUE_APP_API_URL;
}
