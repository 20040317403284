import AgoraRTCClient from "@/agora-rtc";
import IRTCStats from "@/agora-rtc/rtc-stats-interface";
import GooseAPIClient from "@/services/goose-apis";
import LogSend from "@/services/logSend";
import SignalRProxy from "@/services/signalr/signalr-proxy";
import { IAgoraRTCRemoteUser, ILocalTrack, ILocalVideoTrack, IMicrophoneAudioTrack, IRemoteAudioTrack, UID, VideoPlayerConfig } from "agora-rtc-sdk-ng";
import Vue from "vue";
import Component from "vue-class-component";

@Component({})
export default class BaseVue extends Vue {
  public $signalr: SignalRProxy;
  public $logSend: LogSend;
  public $agoraClient: AgoraRTCClient;
  public $gooseapi: GooseAPIClient;
  private _joinLock = Promise.resolve();
  private _joinLockReleaser;
  localVideoTrack: ILocalVideoTrack;
  localAudioTrack: IMicrophoneAudioTrack;
  _rtcStatistics: IRTCStats;
  private _joinedChannel: boolean;
  channel: string;
  audioTrackEnabled: 0 | 1 = 0;
  videoTrackEnabled: 0 | 1 = 0;
  remoteAudioTracks: Map<string, IRemoteAudioTrack> = new Map<string, IRemoteAudioTrack>();
  destroying: boolean;
  remoteUsers: Map<UID, IAgoraRTCRemoteUser> = new Map<UID, IAgoraRTCRemoteUser>();

  constructor() {
    super();

    this.$signalr.startConnection().then(() => {
      const vm = this;
      this.$agoraClient.removeAllListeners("user-joined");
      this.$agoraClient.on("user-joined", this, function(user: IAgoraRTCRemoteUser) {
        this.onRemoteUserJoined(vm, user);
      });
      this.$agoraClient.removeAllListeners("user-left");
      this.$agoraClient.on("user-left", this, function(user: IAgoraRTCRemoteUser) {
        this.onRemoteUserLeft(vm, user);
      });
      this.$agoraClient.removeAllListeners("user-published");
      this.$agoraClient.on("user-published", this, async function(user: IAgoraRTCRemoteUser) {
        vm.callRemoteUserPublished(vm, user);
      });
      this.$agoraClient.removeAllListeners("user-unpublished");
      this.$agoraClient.on("user-unpublished", this, function(user: IAgoraRTCRemoteUser) {
        this.onRemoteUserUnpublished(vm, user);
      });
    });
  }

  async getAgoraToken(channelName: string, uid: number): Promise<string> {
    console.debug("getting Agora security token");
    const token = await this.$gooseapi.getAgoraToken(channelName, uid);
    console.debug("got Agora security token " + token);
    return token;
  }

  async setAudioInput(device: MediaDeviceInfo) {
    if (device && this.localAudioTrack) {
      this.localAudioTrack.setDevice(device.deviceId);
    }
  }

  async setAudioOutput(device: MediaDeviceInfo) {
    this.remoteAudioTracks.forEach(track => {
      track.setPlaybackDevice(device.deviceId);
    });
  }

  public async toggleAudio() {
    if (!this.localAudioTrack) return;
    this.audioTrackEnabled ^= 1;
    this.localAudioTrack.setEnabled(!!this.audioTrackEnabled);
  }

  public async toggleVideo() {
    if (!this.localVideoTrack) return;
    this.videoTrackEnabled ^= 1;
    this.localVideoTrack.setEnabled(!!this.videoTrackEnabled);
  }

  protected camerasPublishing: { cameraId: number; resolver: any; rejecter: any }[] = [
    { cameraId: 10001, resolver: null, rejecter: null },
    { cameraId: 10002, resolver: null, rejecter: null },
    { cameraId: 4444, resolver: null, rejecter: null }
  ];

  public resolvePublishingCamera(cameraId: number) {
    console.log('trying to resolve publishing camera ' + cameraId);
    const resolver = this.camerasPublishing.find(cp => cp.cameraId === cameraId).resolver;
    if (resolver) {
      console.log('found resolver, getting remote user for camera');
      const ru = this.remoteUsers.get(cameraId);
      console.log(ru ? 'found remote user' : 'remote user not found');
      resolver(ru);
      console.log('resolver called');
    }
    else {
      console.error('resolver not found');
    }
  }

  protected cameraIsPublishing(uid: number): Promise<IAgoraRTCRemoteUser> {
    return new Promise<IAgoraRTCRemoteUser>((resolve, reject) => {
      this.camerasPublishing.find(cp => cp.cameraId === uid).resolver = resolve;
      this.camerasPublishing.find(cp => cp.cameraId === uid).rejecter = reject;
    });
  }

  protected async getJoinLock(callback?: (resolver) => void) {
    await this._joinLock;
    this._joinLock = new Promise(resolve => {
      this._joinLockReleaser = resolve;
      if (callback) callback(resolve);
    });
  }

  protected releaseJoinLock() {
    if (!this._joinLockReleaser) return;
    this._joinLockReleaser();
  }

  protected async joinBoothControl(uid: number, booth: string, role: string) {
    await this.$signalr.joinBoothControl(uid, booth, role);
    this.$signalr.$on("reconnected", async () => {
      await this.$signalr.joinBoothControl(uid, booth, role);
    });
  }

  protected async joinBoothChannel(uid: number, booth: string, agoraToken?: string) {
    if (this._joinedChannel) return;
    this.channel = `booth-${booth}`;
    const token = agoraToken || (await this.getAgoraToken(this.channel, uid));
    await this.$agoraClient.join(this.channel, uid, token);
    this._joinedChannel = true;
  }

  protected async leaveBoothChannel() {
    if (!this._joinedChannel) return;
    await this.$agoraClient.leave();
    this._joinedChannel = false;
  }

  protected async playVideoTrackTo(element, videoPlayerConfig?: VideoPlayerConfig) {
    this.localVideoTrack.play(
      element,
      videoPlayerConfig || {
        fit: "cover"
      }
    );
  }

  protected async getCameraTrack(camera: boolean | MediaDeviceInfo | MediaStreamTrack): Promise<ILocalVideoTrack> {
    const videoTrack =
      camera instanceof MediaDeviceInfo || camera == null
        ? await this.$agoraClient.createVideoTrack(camera as MediaDeviceInfo)
        : await this.$agoraClient.createCustomVideoTrack(camera as MediaStreamTrack);
    this.localVideoTrack = videoTrack;
    this.videoTrackEnabled = 1;
    return videoTrack;
  }

  protected async publishTracks(tracks: ILocalTrack | ILocalTrack[]) {
    await this.$agoraClient.publishTracks(tracks);
  }

  protected async unpublishTracks(tracks?: ILocalTrack | ILocalTrack[]) {
    await this.$agoraClient.unpublishTracks(tracks);
  }

  protected async publishStreams(camera: boolean | MediaDeviceInfo | MediaStreamTrack, audio: boolean | MediaDeviceInfo) {
    console.log(camera, audio);
    const tracks = [];

    if (camera !== false) {
      const videoTrack = await this.getCameraTrack(camera);

      tracks.push(videoTrack);
    }
    if (audio !== false) {
      const audioTrack = await this.getAudioTrack(audio);
      if (audioTrack !== null) tracks.push(audioTrack);
    }
    console.log(tracks);
    if (tracks.length) await this.publishTracks(tracks);
  }

  protected async getAudioTrack(audio: boolean | MediaDeviceInfo): Promise<IMicrophoneAudioTrack> {
    const audioTrack = await this.$agoraClient.createAudioTrack(audio instanceof MediaDeviceInfo ? audio : null);
    this.localAudioTrack = audioTrack;
    this.audioTrackEnabled = 1;
    return audioTrack;
  }

  protected async findElementBySelector(selector: string, timeout?: number): Promise<HTMLVideoElement> {
    return new Promise((resolve, reject) => {
      const findVideoInterval = setInterval(() => {
        const video = document.querySelector(selector);
        if (video) {
          clearInterval(findVideoInterval);
          resolve(video as HTMLVideoElement);
        }
      }, 250);
      if (timeout)
        setTimeout(() => {
          clearInterval(findVideoInterval);
          reject(`timeout expired, element ${selector} could not be found`);
        }, timeout);
    });
  }

  protected onRemoteUserJoined(sender, user: IAgoraRTCRemoteUser) {}
  protected onRemoteUserLeft(sender, user: IAgoraRTCRemoteUser) {}

  onRemoteUserPublished(sender, user: IAgoraRTCRemoteUser) {}

  private callRemoteUserPublished(sender, user: IAgoraRTCRemoteUser) {
    if (this.camerasPublishing.find(cp => cp.cameraId === user.uid)?.resolver) {
      console.log("resolving publishing waiter");
      this.camerasPublishing.find(cp => cp.cameraId === user.uid)?.resolver(user);
    }
    sender.onRemoteUserPublished(sender, user);
  }

  protected onRemoteUserUnpublished(sender, user: IAgoraRTCRemoteUser) {}

  addRemoteUser(uid: UID, user: IAgoraRTCRemoteUser) {
    this.remoteUsers.set(uid, user);
  }

  removeRemoteUser(uid: UID) {
    this.remoteUsers.delete(uid);
  }

  enableRTCProfile() {
    this.$agoraClient.addEventListener("stats-available", this.onStatsAvailable);
    this.$agoraClient.enableStats();
  }

  onStatsAvailable(e: CustomEvent<IRTCStats>) {
    this._rtcStatistics = e.detail;
    this.$emit("stats-available", e.detail);
  }

  disableRTCProfile() {
    this.$agoraClient.removeEventListener("stats-available", this.onStatsAvailable);
    this.$agoraClient.disableStats();
  }

  registerEvent(
    eventType:
      | "connection-state-change"
      | "user-joined"
      | "user-left"
      | "user-published"
      | "user-unpublished"
      | "user-info-updated"
      | "media-reconnect-start"
      | "media-reconnect-end"
      | "stream-type-changed"
      | "stream-fallback"
      | "channel-media-relay-state"
      | "channel-media-relay-event"
      | "volume-indicator"
      | "crypt-error"
      | "token-privilege-will-expire"
      | "token-privilege-did-expire"
      | "network-quality"
      | "live-streaming-error"
      | "live-streaming-warning"
      | "stream-inject-status"
      | "exception"
      | "is-using-cloud-proxy",
    sender: BaseVue,
    agora: AgoraRTCClient,
    callback: any
  ) {
    agora.on(eventType, sender, callback);
  }

  public get isRealMode(): boolean {
    console.log(`process.env.VUE_APP_UIMODE => ${process.env.VUE_APP_UIMODE}`);
    return process.env.VUE_APP_UIMODE == "real";
  }

  public async beforeDestroy() {
    this.destroying = true;
    console.log("before destroying");
    await this.beforeDestructor();
    if (this.localVideoTrack) {
      if (this.localVideoTrack.isPlaying) {
        this.localVideoTrack.stop();
      }
      this.localVideoTrack.close();
    }
    if (!this._joinedChannel) return;
    await this.$agoraClient.leave();
    await this.$signalr.stopConnection();
    //    await this.afterDesctructor();
  }

  public async afterDesctructor() {}

  public async beforeDestructor() {}
}
