import { IHubProtocol, IRetryPolicy, LogLevel } from "@microsoft/signalr";
import Vue, { PluginFunction } from "vue";
import SignalRProxy from "./signalr-proxy";

export default class SignalR extends Vue {
  public static install: PluginFunction<SignalrOptions> = (
    __instance,
    options
  ) => {
    console.log("installing signalr");
    __instance.prototype.$signalr = new SignalRProxy(options);
  };
}

export interface SignalrOptions {
  protocol?: IHubProtocol;
  automaticRetryCallback?: IRetryPolicy;
  automaticReconnectRetriesArray?: number[];
  logLevel?: LogLevel;
  url: string;
}

export class ForeverPolicy {
  nextRetryDelayInMilliseconds = (retryContext) => {
    return 3000;
  };
}
