










import AgoraRTCClient from "@/agora-rtc";
import IRTCStats from "@/agora-rtc/rtc-stats-interface";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";

@Component({
  filters: {
    bps: function(value: number) {
      if (typeof value == "undefined") return value;
      const measures = ["bps", "Kbps", "Mbps"];
      let i = 0;
      let result = value.toFixed(2);
      while (value > 0) {
        value = value / 1024;
        if (value <= 0) break;
        if (i >= 2) break;
        result = value.toFixed(2);
        i++;
      }
      return `${result} ${measures[i]}`;
    }
  }
})
export default class RtcStatsPanel extends Vue {
  $agoraClient: AgoraRTCClient;
  @Prop() enabled: boolean;
  rtcStatistics: IRTCStats = {};

  public get setName(): string {
    return this.$route.params["booth"];
  }

  mounted() {
    if (this.enabled) this.enableStats();
    this.$agoraClient.addEventListener("stats-available", this.onStatsAvailable);
  }
  enableStats() {
    this.$agoraClient.enableStats();
  }
  onStatsAvailable(e: CustomEvent<IRTCStats>) {
    this.rtcStatistics = e.detail;
  }

  @Watch("enabled")
  onEnabledChange(value: boolean) {
    console.log(`enabled changing value to ${value}`);
    if (value) this.enableStats();
    else this.$agoraClient.disableStats();
  }
}
