
















import { Component, Vue, Prop } from "vue-property-decorator";

@Component({
    name: 'CustomOptions',
})
export default class CustomOptions extends Vue {
    @Prop({ type: String, default: 'default' }) theme: string;
    @Prop({ type: Boolean, required: true }) value: boolean;

    constructor() {
        super();
        // const vm = this;
    }
    public closeOptions(el) {
        if(this.value && !this.findParent(el.target, 'control-panel__column--left')) this.$emit("input", !this.value);
    }

    public findParent(el, cls) {
        while ((el = el.parentElement) && !el.classList.contains(cls));
        return el;
    }
}
