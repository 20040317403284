import jwtDecode, { JwtPayload } from "jwt-decode";
import Vue from "vue";
import { PluginFunction } from "vue";

export default class GooseAPIClient extends Vue {
  public static install: PluginFunction<GooseAPIClientOptions> = (__instance, options) => {
    __instance.prototype.$gooseapi = new GooseAPIClient(options);
  };
  baseUrl: string;

  constructor(options: GooseAPIClientOptions) {
    super();

    this.baseUrl = options.baseUrl;
  }

  async closeCall(slug: string): Promise<CloseCallResult> {
    const response = await fetch(`${this.baseUrl}/calls/${slug}/close`, {
      method: "put",
      headers: this.getAuthorizedRequestHeaders()
    });
    const result = await (response.json() as Promise<CloseCallResult>);
    return {
      success: response.ok,
      ...result
    };
  }

  async getCallBySlug(slug: string): Promise<GetCallResult> {
    const response = await fetch(`${this.baseUrl}/calls/${slug}`, {
      method: "get",
      headers: {
        "Content-Type": "application/json"
      }
    });
    return {
      success: response.ok,
      ...(await (response.json() as Promise<GetCallResult>))
    };
  }

  async createCall(booth: string, startCallAt: Date) {
    const response = await fetch(this.baseUrl + "/calls", {
      method: "post",
      headers: this.getAuthorizedRequestHeaders(),
      body: JSON.stringify({
        booth,
        startCallAt
      })
    });
    const result = await (response.json() as Promise<CreateCallResult>);
    return {
      success: response.ok,
      ...result
    };
  }

  async occupyBooth(booth: string, role: string): Promise<{ occupied: boolean; message?: string }> {
    const response = await fetch(this.baseUrl + `/booths/${booth}/${role === "seller" ? "occupy" : "occupy-as-assistant"}`, {
      method: "put",
      headers: this.getAuthorizedRequestHeaders()
    });
    if (response.ok) {
      return { occupied: true };
    }
    return { ...(await response.json()), occupied: false };
  }

  async freeBooth(booth: string): Promise<{ occupied: boolean; message?: string }> {
    const response = await fetch(this.baseUrl + `/booths/${booth}/free`, {
      method: "put",
      headers: this.getAuthorizedRequestHeaders()
    });
    if (response.ok) {
      return { occupied: false };
    }
    return { ...(await response.json()), occupied: true };
  }

  getUserRole() {
    const authToken = this.getCurrentToken();
    if (!authToken) return null;
    const jwt = jwtDecode<JwtPayload>(authToken);
    if (jwt) {
      return jwt["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/role"];
    }
    return null;
  }

  getUsername() {
    const authToken = this.getCurrentToken();
    if (!authToken) return null;
    const jwt = jwtDecode<JwtPayload>(authToken);
    if (jwt) {
      return jwt["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"];
    }
    return null;
  }

  async getBooths(onlyFree?: boolean): Promise<Booth[]> {
    const response = await fetch(this.baseUrl + `/booths?${onlyFree ? "filter.free=true" : ""}`, {
      method: "get",
      headers: this.getAuthorizedRequestHeaders()
    });
    if (response.ok) {
      return await (response.json() as Promise<Booth[]>);
    }
    return [];
  }

  async login(username: string, password: string, booth: string): Promise<LoginResult> {
    const response = await fetch(this.baseUrl + "/auth", {
      method: "post",
      body: JSON.stringify({
        username,
        password,
        booth
      }),
      headers: {
        "Content-Type": "application/json"
      }
    });

    const result: LoginResult = await response.json();

    localStorage.setItem("sales-booth-auth", `${result.token}`);
    return {
      ...result,
      success: response.ok
    };
  }

  async getAgoraToken(channelName: string, uid: number): Promise<string> {
    const authToken = this.getCurrentToken();
    const response = await fetch(this.baseUrl + "/AgoraToken", {
      body: JSON.stringify({
        channelName,
        uid
      }),
      method: "post",
      headers: this.getAuthorizedRequestHeaders()
    });
    if (response.ok) {
      return await response.text();
    }
  }

  getCurrentToken() {
    return localStorage.getItem("sales-booth-auth");
  }

  getAuthorizedRequestHeaders(): HeadersInit {
    const authToken = localStorage.getItem("sales-booth-auth");
    return {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`
    };
  }
}

export interface GooseAPIClientOptions {
  baseUrl: string;
}

export interface LoginResult extends BaseResult {
  success: boolean;
  role?: string;
  token?: string;
  cameraId?: string;
  error?: string;
  startupBoothSlug?: string;
}

export interface Booth {
  id: number;
  name: string;
  slug: string;
}

export interface CreateCallResult extends BaseResult {
  slug?: string;
}

export interface GetCallResult extends BaseResult {
  isClosed: boolean;
  id: number;
  slug: string;
  booth: string;
  channel: string;
  buyerAgoraToken: string;
  startsAtUtc: Date;
  endsAtUtc: Date;
  date: Date;
}

export interface CloseCallResult extends BaseResult {}

export interface BaseResult {
  success: boolean;
  message?: string;
}
