










import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "Modal",
  data() {
    return {};
  }
})
export default class Modal extends Vue {
  @Prop({ required: true, type: Boolean }) value: boolean;

  constructor() {
    super();
    // const vm = this;
  }

  closeModal(el) {
    if (el.target.classList.contains("modal")) {
      this.$emit("input", !this.$props.value);
    }
  }
}
