import Vue from "vue";
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from "vue-router";
import Home from "../views/Home.vue";
import Camera from "../views/Camera.vue";
import Seller from "../views/Seller.vue";
import Buyer from "../views/Buyer.vue";
import Assistant from "../views/Assistant.vue";
import ByePage from "../views/ByePage.vue";
import ErrorPage from "../views/ErrorPage.vue";
import Logs from "../views/Logs.vue";
import jwtDecode, { JwtPayload } from "jwt-decode";
import GooseAPIClient, { GetCallResult } from "@/services/goose-apis";

Vue.use(VueRouter);

class BuyerCallPathGuardFactory extends Vue {
  $gooseapi: GooseAPIClient;
  async guard(to: Route, _, next: NavigationGuardNext) {
    const getCallResult = await this.$gooseapi.getCallBySlug(to.params["callSlug"]);
    if (getCallResult.success) {
      if (getCallResult.isClosed) {
        next({ path: "/bye" });
      }
      localStorage.setItem("callInfo", JSON.stringify(getCallResult));
      next();
      return;
    } else {
      alert(getCallResult.message || "Cannot proceed, an internal error occurred. Please try again.");
    }
    next({
      name: "error",
      params: { error: getCallResult.message }
    });
  }
}

export class UserMustBeLoggedAndInRole {
  role: string;
  constructor(role: string) {
    this.role = role;
  }

  async guard(to: Route, from, next: NavigationGuardNext) {
    const tokenStr = localStorage.getItem("sales-booth-auth");
    let token: JwtPayload;
    try {
      token = jwtDecode<JwtPayload>(tokenStr);
    } catch (error) {
      console.warn("Could not decode token " + tokenStr);
    }
    if (token?.exp && new Date(token.exp * 1000) > new Date()) {
      if (token["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"] === this.role) {
        next();
        return;
      }
    }
    alert("Please login to enter");
    if (router.currentRoute.name !== "Home") {
      next({
        name: "Home",
        query: { returnUrl: encodeURIComponent(to.path), requiredRole: this.role }
      });
      return;
    }
    next();
  }
}
const routes: RouteConfig[] = [
  {
    path: "/camera/:booth?/:cameraId?",
    name: "camera",
    component: Camera,
    props: true,
    beforeEnter: (to, from, next) => new UserMustBeLoggedAndInRole("camera").guard(to, from, next)
  },
  {
    path: "/seller/:booth?",
    name: "seller",
    component: Seller,
    props: true,
    beforeEnter: (to, from, next) => new UserMustBeLoggedAndInRole("seller").guard(to, from, next)
  },
  {
    path: "/buyer/:booth?",
    name: "buyer",
    component: Buyer,
    props: true
  },
  {
    path: "/assistant/:booth?",
    name: "assistant",
    component: Assistant,
    props: true,
    beforeEnter: (to, from, next) => new UserMustBeLoggedAndInRole("assistant").guard(to, from, next)
  },
  {
    path: "/logs/:booth?",
    name: "logs",
    component: Logs,
    props: true
  },
  {
    path: "/bye/:callSlug?",
    name: "bye",
    component: ByePage,
    props: true
  },
  {
    path: "/error",
    name: "error",
    component: ErrorPage
  },
  {
    path: "/:callSlug",
    name: "Call",
    component: Buyer,
    beforeEnter: (to, from, next) => new BuyerCallPathGuardFactory().guard(to, from, next)
  },
  {
    path: "/",
    name: "Home",
    component: Home
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
