





import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component({})
export default class ErrorPage extends Vue {
  @Prop({ default: "Generic error" }) error: string;
}
